const BASE_URL = process.env.REACT_APP_BASE_URL;

const LOGIN = `${BASE_URL}/users/admin/login`;
const REFRESH_TOKEN = `${BASE_URL}/users/admin/refresh-token`;

const SEND_OTP_PAYMENT = `${BASE_URL}/payment/send-otp`;
const VERIFY_OTP_PAYMENT = `${BASE_URL}/payment/verify-otp`;
const PAYMENT_APPROVAL = `${BASE_URL}/payment/deposit/callback`;
const PENDING_PAYMENTS = `${BASE_URL}/payment/pending/request`;
const COMPLETED_PAYMENTS = `${BASE_URL}/payment/completed`;

const URLs = {
  LOGIN,
  REFRESH_TOKEN,
  SEND_OTP_PAYMENT,
  VERIFY_OTP_PAYMENT,
  PAYMENT_APPROVAL,
  PENDING_PAYMENTS,
  COMPLETED_PAYMENTS,
};

export default URLs;
