import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

const Formlogin = ({
  errors,
  handleBlur,
  handleChange,
  touched,
  values,
  handleSubmit,
}) => {
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const navigate = useNavigate();

  //Handle login admin
  const loginn = async (e) => {
    e.preventDefault();
    const email = values.email;
    const password = values.password;
    handleSubmit();
    const sendData = {
      email,
      password,
    };
    const API_URL = `${process.env.REACT_APP_BASE_URL}/users/admin/login`;
    try {
      const data = await axios.post(API_URL, sendData);

      if (data.data.auth) {
        toast.success(data.data.message);
        localStorage.setItem("token", "Bearer " + data.data.token);
        localStorage.setItem(
          "refreshToken",
          "Bearer " + data.data.refreshToken
        );
        localStorage.setItem("name", data.data.details.name);
        localStorage.setItem("email", data.data.details.email);
        localStorage.setItem("loginStatus", true);

        setTimeout(() => {
          navigate("/payments");
        }, 1000);
      }
    } catch (e) {
      //console.log(e.response);
      toast.error(e.response.data.message);
    }
  };

  const onCaptchaChange = (value) => {
    if (value) setCaptchaVerified(true);
    else setCaptchaVerified(false);
  };

  return (
    <div className="d-flex h-screen">
      <div className="w-50 bg-primary"></div>
      <div className="w-50 bg-white d-flex align-items-center justify-content-center">
        <div className="w-50 f-16 text-center">
          <p className="text-center">
            <img
              src="/logo.png"
              alt="logo"
              style={{ width: "100px", height: "120px" }}
            />
          </p>
          <form onSubmit={loginn}>
            <input
              type="email"
              className={errors.email ? "red login_input" : "login_input"}
              placeholder="Email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
            {errors.email && (
              <div style={{ color: "red" }}>
                {touched.email && errors.email}
              </div>
            )}
            <div className="my-4">
              <input
                type="password"
                className={errors.password ? "red login_input" : "login_input"}
                placeholder="Password"
                value={values.password}
                onChange={handleChange}
                name="password"
                onBlur={handleBlur}
                required
              />
              {errors.password && (
                <div style={{ color: "red" }}>
                  {touched.password && errors.password}
                </div>
              )}
            </div>

            <div className="row mt-3 mb-3 mx-auto">
              <div className="col-sm-12 text-center">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={onCaptchaChange}
                />
              </div>
            </div>

            <button
              className="btn bg-primary text-white mb-20"
              type="submit"
              disabled={!captchaVerified}
            >
              Login
            </button>

            {/* <div className="sign-up ">
              Don't have an account?
              <Link
                to="/signup"
                className="text-primary w-49 mb-20 text-decoration-none ms-2"
              >
                Create One
              </Link>
            </div> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Formlogin;
