import Header from "./Header";
import Sidebar from "./Sidebar";

const withLayout = (Component) => {
  return (props) => (
    <div className="position-relative d-flex flex-column h-screen">
      <Header />
      <div className="h-100 w-100 flex-1 overflow-hidden d-flex gap-4 px-4 pb-3">
        <Sidebar />
        <div className="flex-1 h-100 w-100 overflow-auto">
          <Component {...props} />
        </div>
      </div>
    </div>
  );
};

export default withLayout;
