import "./header.css";

// icons
// import { VscSearch } from "react-icons/vsc";
// import { IoIosNotificationsOutline } from "react-icons/io";
// import { BsPerson } from "react-icons/bs";

const Header = () => {
  return (
    <div className="header d-flex align-items-center justify-content-between">
      <div className="header_img_div"></div>

      {/* <div className="d-flex align-items-center justify-content-center gap-3 f-14">
        <div className="rounded-circle bg-white px-3 py-2">
          <VscSearch />
        </div>
        <div className="rounded-circle bg-white px-3 py-2">
          <IoIosNotificationsOutline />
        </div>
        <div className="rounded-circle bg-white px-3 py-2">
          <BsPerson />
        </div>
      </div> */}
    </div>
  );
};

export default Header;
