import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";

import URLs from "../../utils/apis";
import api from "../../services/apiService";

function ConfirmOTPModal({ show, onHide, orderData, fetchData }) {
  const [otp, setOTP] = useState("");
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    if (!otp) return;

    try {
      setLoading(true);
      await api.post(URLs.VERIFY_OTP_PAYMENT, {
        email: localStorage.getItem("email"),
        otp: otp,
        orderId: orderData?.id
      });

      await api.post(URLs.PAYMENT_APPROVAL, {
        orderId: orderData.id,
        responseCode: orderData?.admin_status || "FAILED",
        amount: orderData?.amount,
      });

      setOTP("");
      onHide();
      toast.success("Payment status updated successfully");
      fetchData();
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={() => {
      setOTP("");
      onHide();
    }} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          Request for{" "}
          <span
            className={`fw-bold ${
              orderData?.admin_status === "SUCCESS"
                ? "text-success"
                : "text-danger"
            }`}
          >
            {orderData?.admin_status === "SUCCESS" ? "Approve" : "Decline"}
          </span>{" "}
          of payment for orderId:{" "}
          <span className="fw-bold">{orderData?.id}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>OTP:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOTP(e.target.value)}
          />
        </Form.Group>
        <p>
          Please enter OTP sent to your email address for final confirmation
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {
          setOTP("");
          onHide();
        }} disabled={loading}>
          Close
        </Button>
        <Button variant="primary" onClick={handleConfirm} disabled={loading}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmOTPModal;
