import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import withLayout from "../../Layout";
import moment from "moment";

import api from ".././../services/apiService";
import URLs from "../../utils/apis";

import "react-data-table-component-extensions/dist/index.css";

const CompletedPayments = () => {
  const [payments, setPayments] = useState([]);

  const fetchData = async () => {
    try {
      const res = await api.get(URLs.COMPLETED_PAYMENTS);
      setPayments(res.data?.transactions);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = useMemo(
    () => [
      {
        name: "OrderId",
        selector: (row) => row.id,
        sortable: true,
      },
      {
        name: "UserId",
        selector: (row) => row.user_id,
        sortable: true,
        wrap: true,
      },
      {
        name: "Amount",
        selector: (row) => row.amount,
        sortable: true,
        wrap: true,
      },
      {
        name: "Date",
        selector: (row) => `${moment(row.createdAt).format('M/D/YYYY HH:mm:ss')}`,
        sortable: true,
        wrap: true,
      },
      {
        name: "Status",
        selector: (row) => `${row.transaction?.status}`,
        sortable: true,
        wrap: true,
      },
    ],
    []
  );

  const tableData = {
    columns,
    data: payments,
  };

  const navigate = useNavigate();

  useEffect(() => {
    let status = localStorage.getItem("loginStatus");
    if (!status) {
      navigate("/");
    }

    fetchData();
  }, []);

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontSize: "16px",
        fontWeight: 600,
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontWeight: 500,
        fontSize: "16px",
      },
    },
  };

  return (
    <div className="bg-white rounded-lg p-4 m-1 h-100 w-100 flex-1 overflow-y-scroll">
      <h1>Completed Payments</h1>
      <DataTableExtensions {...tableData} print={false} export={false}>
        <DataTable
          columns={columns}
          data={payments}
          noHeader
          defaultSortField="id"
          defaultSortAsc={false}
          customStyles={customStyles}
          pagination
          paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
          conditionalRowStyles={[
            {
                when: row => row?.transaction?.status === "SUCCESS",
                style: {
                    color: "green",
                }
            },
            {
                when: row => row?.transaction?.status !== "SUCCESS",
                style: {
                    color: "red",
                }
            }
          ]}
        />
      </DataTableExtensions>
    </div>
  );
};

export default withLayout(CompletedPayments);
