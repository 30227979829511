/* eslint-disable jsx-a11y/anchor-is-valid */
// import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

//icons
// import { RxDashboard } from "react-icons/rx";
import { BsWallet } from "react-icons/bs";
import { SlLogout } from "react-icons/sl";

// services
// import api from "../../services/apiService";

import "./sidebar.css";
// import apiUrls from "../../utils/apiUrls";

const Sidebar = () => {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  };

  return (
    <div className="sidebar bg-white h-100 position-relative">
      <div className="d-flex align-items-center justify-content-center p-3 border-bottom">
        <p className="text-center fs-4 mb-0">Welcome {localStorage?.getItem("name") || "Admin"}!</p>
      </div>
      <nav className="mt-4 d-flex flex-column gap-4">
        {/* <Link
          to="/adminhome"
          className="sidebar_link d-flex align-items-center mx-3 text-decoration-none"
        >
          <span>
            <RxDashboard color="#000" style={{ fontSize: "2rem" }} />
          </span>
          <span className="ms-2 f-16">Home</span>
        </Link>
        <Link
          to="/adminhome"
          className="sidebar_link d-flex align-items-center mx-3 text-decoration-none"
        >
          <span>
            <RxDashboard color="#000" style={{ fontSize: "2rem" }} />
          </span>
          <span className="ms-2 f-16">Contests</span>
        </Link> */}

        <Link
          to="/payments/pending"
          className="sidebar_link d-flex align-items-center mx-3 text-decoration-none"
        >
          <span>
            <BsWallet color="#000" style={{ fontSize: "2rem" }} />
          </span>
          <span className="ms-2 f-16">Pending Payments</span>
        </Link>

        <Link
          to="/payments"
          className="sidebar_link d-flex align-items-center mx-3 text-decoration-none"
        >
          <span>
            <BsWallet color="#000" style={{ fontSize: "2rem" }} />
          </span>
          <span className="ms-2 f-16">Completed Payments</span>
        </Link>
      </nav>
      <div className="position-absolute bottom-0 px-4 pt-3 my-4 w-100 border-top sidebar _logout">
        <div
          className="d-flex align-items-center ms-2 me-4 text-decoration-none  cursor-pointer"
          onClick={logout}
        >
          <span>
            <SlLogout color="#000" style={{ fontSize: "2rem" }} />
          </span>
          <span className="px-3 f-16">Logout</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
