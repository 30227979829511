import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import moment from 'moment'

import ConfirmOTPModal from "./ConfirmOtp";
import withLayout from "../../Layout";
import api from ".././../services/apiService";
import URLs from "../../utils/apis";

import "react-data-table-component-extensions/dist/index.css";

const Payments = () => {
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pendingData, setPendingData] = useState([]);

  const fetchData = async () => {
    try {
      const res = await api.get(URLs.PENDING_PAYMENTS);
      setPendingData(res.data?.ordersWithNullTransaction);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = useMemo(
    () => [
      {
        name: "OrderId",
        selector: (row) => row.id,
        sortable: true,
      },
      {
        name: "UserId",
        selector: (row) => row.user_id,
        sortable: true,
        wrap: true,
      },
      {
        name: "Amount",
        selector: (row) => row.amount,
        sortable: true,
        wrap: true,
      },
      {
        name: "Date",
        selector: (row) => `${moment(row.createdAt).format('M/D/YYYY HH:mm:ss')}`,
        sortable: true,
      },
      {
        name: "Status",
        selector: (row) => "Pending",
        sortable: true,
        wrap: true,
      },
      {
        name: "Actions",
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record, e, index) => {
          return (
            <>
              <button
                className="btn btn-outline-success "
                size="lg"
                disabled={loading}
                onClick={() => sendOTP({ ...record, admin_status: "SUCCESS" })}
              >
                Approve
              </button>
              &nbsp; &nbsp;
              <button
                className="btn btn-outline-danger"
                size="lg"
                disabled={loading}
                onClick={() => sendOTP({ ...record, admin_status: "FAILED" })}
              >
                Decline
              </button>
            </>
          );
        },
      },
    ],
    []
  );

  const tableData = {
    columns,
    data: pendingData,
  };

  const navigate = useNavigate();

  useEffect(() => {
    let status = localStorage.getItem("loginStatus");
    if (!status) {
      navigate("/");
    }

    fetchData();
  }, []);

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontSize: "16px",
        fontWeight: 600,
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontWeight: 500,
        fontSize: "16px",
      },
    },
  };

  const sendOTP = async (data) => {
    setOrderData(data);

    toast.info("Please wait for some time, an OTP will be sent to your email");

    try {
      setLoading(true);
      const res = await api.post(URLs.SEND_OTP_PAYMENT, {
        orderId: String(data?.id),
      });
      toast.success(res?.data?.message);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-lg p-4 m-1 h-100 w-100 flex-1 overflow-y-scroll">
      <h1>Pending Payments</h1>
      <DataTableExtensions {...tableData} print={false} export={false}>
        <DataTable
          columns={columns}
          data={pendingData}
          noHeader
          defaultSortField="id"
          defaultSortAsc={false}
          customStyles={customStyles}
          pagination
          paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
        />
      </DataTableExtensions>

      <ConfirmOTPModal
        show={!!orderData}
        orderData={orderData}
        onHide={() => setOrderData(null)}
        fetchData={fetchData}
      />
    </div>
  );
};

export default withLayout(Payments);
