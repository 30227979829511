import React, { useEffect } from "react";
// import axios from "axios";
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  useNavigate,
  // Navigate,
} from "react-router-dom";
import "./login.css";
import FormVanillaLogin from "./FormVanillasLogin";

function Login() {

  const navigate = useNavigate();

  const emailValidation = (email) => {
    if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      return null;
    }
    if (email.trim() === "") {
      return "Email is required";
    }
    return "Please enter a valid email";
  };

  const passwordValidation = (password) => {
    if (
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{5,14}$/.test(
        password
      )
    ) {
      return null;
    }
    if (password.trim() == "") {
      return "Password is required";
    }

    return "Password should contain minimum five and maximum 14 characters, at least one uppercase letter, one lowercase letter, one number and one special character";
  };

  const validate = {
    email: emailValidation,
    password: passwordValidation,
  };

  const initialValues = {
    email: "",
    password: "",
  };

  useEffect(() => {
    const refreshToken = localStorage.getItem("refreshToken");
    const status = localStorage.getItem("loginStatus");

    if (refreshToken && status) {
      navigate("/payments");
    }
  }, []);

  return (
    <>
      <FormVanillaLogin validate={validate} initialValues={initialValues} />
    </>
  );
}
export default Login;
