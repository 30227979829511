import axios from "axios";
import URLs from "../utils/apis";

const instance = axios.create();

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["authorization"] = token;
    }
    console.log("inside interceptor", config.headers);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== URLs.LOGIN && err.response) {
      // refresh token was expired or invalid
      if (
        originalConfig.url === URLs.REFRESH_TOKEN &&
        err?.response?.status === 403
      ) {
        return Promise.reject(err);
      }

      // Access Token was expired or token was not found in db
      if (
        !originalConfig._retry &&
        (err.response.status === 401 || err.response.status === 403)
      ) {
        originalConfig._retry = true;

        try {
          const rs = await axios.post(URLs.REFRESH_TOKEN, {
            refreshToken: localStorage.getItem("refreshToken"),
          });

          localStorage.setItem("token", `Bearer ${rs.data?.token}`);
          return instance(originalConfig);
        } catch (errors) {
          localStorage.clear();
          window.location.href = "/";
          return Promise.reject(errors);
        }
      } else if (
        err.response.status === 403 &&
        err?.response?.data?.message === "Forbidden error"
      ) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/";
        return Promise.reject(err);
      } else {
        return Promise.reject(err);
      }
    }
    return Promise.reject(err);
  }
);
export default instance;
