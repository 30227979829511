import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import AdminHome from "./pages/AdminHome/adminHome";
import Login from "./pages/Login/Login";
// import Contest from "./pages/Contest/Contest";
// import Signup from "./pages/Signup/Signup";
import Payments from "./pages/Payments";
import CompletedPayments from "./pages/Payments/CompletedPayments";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        {/* <Route path="/signup" element={<Signup />} /> */}
        {/* <Route path="/adminHome" element={<AdminHome />} /> */}
        {/* <Route exact path="/contest" element={<Contest />} /> */}
        <Route exact path="/payments" element={<CompletedPayments />} />
        <Route exact path="/payments/pending" element={<Payments />} />
      </Routes>
      <ToastContainer autoClose={3000} />
    </>
  );
}

export default App;
